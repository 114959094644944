export const SIZES = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
};

export const CONTROL_SIZES = {
  [SIZES.XS]: 7,
  [SIZES.SM]: 9,
  [SIZES.MD]: 11,
  [SIZES.LG]: 14,
};

export const LAYOUT = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
  INLINE: "inline",
};

export const DIRECTIONS = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left",
};

export const SELECTION_MODES = {
  YEAR: "year",
  MONTH: "month",
  DAY: "day",
};

export const PICKER_VIEWS = {
  YEAR: "year",
  MONTH: "month",
  DATE: "date",
};

export const STATUS = {
  DANGER: "danger",
  SUCCESS: "success",
  WARNING: "warning",
};

export const STEPS_STATUS = {
  COMPLETE: "complete",
  PENDING: "pending",
  IN_PROGRESS: "in-progress",
  ERROR: "error",
};

export const PLACEMENT = {
  TOP_START: "top-start",
  TOP_CENTER: "top-center",
  TOP_END: "top-end",
  BOTTOM_START: "bottom-start",
  BOTTOM_CENTER: "bottom-center",
  BOTTOM_END: "bottom-end",
  MIDDLE_START_TOP: "middle-start-top",
  MIDDLE_START_BOTTOM: "middle-start-bottom",
  MIDDLE_END_TOP: "middle-end-top",
  MIDDLE_END_BOTTOM: "middle-end-bottom",
};

export const DROPDOWN_ITEM_TYPE = {
  DEFAULT: "default",
  HEADER: "header",
  DIVIDER: "divider",
  CUSTOM: "custom",
};

export const HOURS = [
  {
    label: "09:00am",
    value: "09,00",
    hour: 9,
    minute: 0,
  },
  // {
  //   label: "09:30am",
  //   value: "09,30",
  //   hour: 9,
  //   minute: 30,
  // },
  {
    label: "10:00am",
    value: "10,00",
    hour: 10,
    minute: 0,
  },
  // {
  //   label: "10:30am",
  //   value: "10,30",
  //   hour: 10,
  //   minute: 30,
  // },
  {
    label: "11:00am",
    value: "11,00",
    hour: 11,
    minute: 0,
  },
  // {
  //   label: "11:30am",
  //   value: "11,30",
  //   hour: 11,
  //   minute: 30,
  // },
  {
    label: "12:00pm",
    value: "12,00",
    hour: 12,
    minute: 0,
  },
  // {
  //   label: "12:30pm",
  //   value: "12,30",
  //   hour: 12,
  //   minute: 30,
  // },
  {
    label: "1:00pm",
    value: "13,00",
    hour: 13,
    minute: 0,
  },
  // {
  //   label: "1:30pm",
  //   value: "13,30",
  //   hour: 13,
  //   minute: 30,
  // },
  {
    label: "2:00pm",
    value: "14,00",
    hour: 14,
    minute: 0,
  },
  // {
  //   label: "2:30pm",
  //   value: "14,30",
  //   hour: 14,
  //   minute: 30,
  // },
  {
    label: "3:00pm",
    value: "15,00",
    hour: 15,
    minute: 0,
  },
  // {
  //   label: "3:30pm",
  //   value: "15,30",
  //   hour: 15,
  //   minute: 30,
  // },
  {
    label: "4:00pm",
    value: "16,00",
    hour: 16,
    minute: 0,
  },
  // {
  //   label: "4:30pm",
  //   value: "16,30",
  //   hour: 16,
  //   minute: 30,
  // },
  {
    label: "5:00pm",
    value: "17,00",
    hour: 17,
    minute: 0,
  },
];

export const DAY_DURATION = 86400000;

export const dateFormat = "YYYY-MM-DD HH:mm:ss";
