import React, { useState } from "react";
import { motion } from "framer-motion";
import { HiArrowUp, HiArrowDown, HiOutlineTrash } from "react-icons/hi";
import ConfirmDialog from "./ConfirmDialog";

const FormDropdown = ({
  children,
  id,
  title,
  defaultOpen,
  onDelete,
  hideDelete,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  return (
    <>
      <div>
        <div className="filter-dropdown mb-5 rounded-md bg-slate-100 border p-2 min-h-[42px] flex items-center">
          <h6
            id={id}
            className="flex items-center justify-between cursor-pointer group w-full"
            onClick={(e) => setIsOpen(!isOpen)}
          >
            <div
              id={id}
              className="select-none relative flex flex-cl xl:flex-row items-center gap-1"
            >
              {title}
              {!hideDelete && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsConfirmModalOpen(true);
                  }}
                  className="cursor-pointer text-lg hover:text-red-500 group-hover:flex hidden"
                >
                  <HiOutlineTrash />
                </span>
              )}
            </div>
            {isOpen ? <HiArrowUp id={id} /> : <HiArrowDown id={id} />}
          </h6>
        </div>
        <motion.div
          initial={{ opacity: 0, height: 0, overflowY: "hidden" }}
          animate={{
            opacity: !isOpen ? 0 : 1,
            height: !isOpen ? 0 : "auto",
            marginBottom: !isOpen ? 0 : "20px",
            overflowY: !isOpen ? "hidden" : "initial",
          }}
          transition={{ duration: 0.15 }}
        >
          {children}
        </motion.div>
      </div>
      <ConfirmDialog
        isOpen={isConfirmModalOpen}
        type="danger"
        title="Delete"
        onClose={() => setIsConfirmModalOpen(false)}
        onCancel={() => setIsConfirmModalOpen(false)}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          onDelete();
          setIsConfirmModalOpen(false);
        }}
        confirmButtonColor="red-600"
      >
        <p>Are you sure you want to delete?</p>
      </ConfirmDialog>
    </>
  );
};

export default FormDropdown;
