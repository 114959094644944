import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import PropTypes from "prop-types";
import "react-phone-number-input/style.css";
import { getIn } from "formik";

const PhoneInputField = (props) => {
  const {
    className,
    field: { name, value },
    form: {
      errors,
      handleBlur,
      setFieldValue,
      touched,
      setFieldError,
      setFieldTouched,
    },
    form,
    label,
    isValid,
    country,
    onChange,
    onBlur,
    disabled,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const isError = getIn(touched, name) && getIn(errors, name);
  const errorStyle = isError ? "error" : "";
  const filledStyle = isFocused || value ? "filled" : "";
  const disabledStyle = disabled ? "input-disabled" : "";

  const onValueChange = (phoneNumber) => {
    if (typeof phoneNumber === "undefined") {
      setFieldValue(name, "");
    } else {
      setFieldValue(name, phoneNumber);
      handleValidation(isValidPhoneNumber(phoneNumber), phoneNumber);
    }
  };

  const handleValidation = async (boolean, phoneNumber) => {
    await setFieldTouched(name, true, true);
    // setFieldError(
    //   name,
    //   !boolean ? "Please enter valid phone number." : undefined
    // );
  };

  return (
    <div
      className={`${className} ${errorStyle} ${filledStyle} ${disabledStyle} text-input-group`}
    >
      <PhoneInput
        className={`input input-md h-12 ${
          isError || (value && !isValidPhoneNumber(value))
            ? "input-invalid"
            : "focus:ring-green-500 focus-within:ring-green-500 focus-within:border-green-500 focus:border-green-500"
        }`}
        international
        placeholder="Phone Number"
        name={name}
        value={value}
        disabled={disabled}
        onChange={onValueChange}
        // onBlur={(a) => onValueChange(a.target.value)}
        defaultCountry="US"
        country={country}
      />
    </div>
  );
};

PhoneInputField.propTypes = {
  className: PropTypes.string,
  form: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  country: PropTypes.string,
  disabled: PropTypes.bool,
};

PhoneInputField.defaultProps = {
  className: "",
  label: "",
  onChange: null,
  country: "US",
  disabled: false,
};

export default PhoneInputField;
