import queryString from "query-string";
import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: `User/Login?${queryString.stringify(data, {
      skipNull: true,
    })}`,
    method: "post",
  });
}

export async function apiSignInWithUser(data) {
  return ApiService.fetchData({
    url: `User/LoginWithUser?id=${data}`,
    method: "get",
  });
}

export async function apiGetUserPermission(token) {
  return ApiService.fetchData({
    url: "User/MyPermissions",
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: `User/ForgotPassword?email=${data.email}`,
    method: "get",
  });
}

export async function apiForgotPasswordCheckCode(data) {
  return ApiService.fetchData({
    url: `User/ForgotPasswordCheckCode`,
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "User/ForgotPassword",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}
