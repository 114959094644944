import { dateFormat, HOURS } from "components/ui/utils/constant";
import dayjs from "dayjs";

export const reservationBikeStatuses = {
  notAssignd: {
    label: "Not Assigned",
    colors: "bg-slate-600 text-white",
  },
  noshow: {
    label: "No Show",
    colors: "bg-purple-600 text-white",
  },
  skip: {
    label: "Skipped",
    colors: "bg-orange-600 text-white",
  },
  stolen: {
    label: "Stolen",
    colors: "bg-red-600 text-white",
  },
  checkedIn: {
    label: "Checked In",
    colors: "bg-yellow-600 text-white",
  },
  droppedOff: {
    label: "Dropped Off",
    colors: "bg-green-600 text-white",
  },
};

export const conciergeReservationStatuses = [
  { label: "Pending", value: 0 },
  { label: "Confirmed", value: 10 },
  { label: "Rejected", value: 20 },
  { label: "Paid", value: 30 },
];

export const durationTypes = [
  { display: "Minute(s)", label: "Minutely", value: 10 },
  { display: "Hour(s)", label: "Hourly", value: 20 },
  { display: "Day(s)", label: "Daily", value: 30 },
  { display: "Month(s)", label: "Monthly", value: 40 },
];

export const conciergeReservationTypes = [
  { label: "Rental", value: 0 },
  { label: "Tour", value: 10 },
];

export const reviewRates = [
  { label: "Terrible", value: 10, colors: "text-red-500" },
  { label: "Okay", value: 20, colors: "text-yellow-500" },
  { label: "Excellent", value: 30, colors: "text-green-500" },
];

export const reservationPlatforms = [
  { label: "Online", value: "Online" },
  { label: "POS", value: "POS" },
  { label: "Direct", value: "Direct" },
  { label: "Reseller", value: "Reseller" },
];

export const reservationStatuses = [
  { label: "Draft", value: "draft", colors: "bg-slate-600 text-white" },
  { label: "Booked", value: "booked", colors: "bg-slate-600 text-white" },
  { label: "Canceled", value: "canceled", colors: "bg-red-600 text-white" },
  {
    label: "Partially Picked Up",
    value: "PartiallyPickedUp",
    colors: "bg-yellow-600 text-white",
  },
  { label: "Picked Up", value: "PickedUp", colors: "bg-blue-600 text-white" },
  { label: "Returned", value: "DropOff", colors: "bg-blue-600 text-white" },
  { label: "Rebooked", value: "rebooked", colors: "bg-slate-600 text-white" },
  { label: "Completed", value: "completed", colors: "bg-green-600 text-white" },
  { label: "No Show", value: "noshow", colors: "bg-yellow-600 text-white" },
];

export const reservationSoftStatuses = [
  {
    label: "Not Checked",
    value: 0,
    selectable: true,
    colors: "bg-slate-600 text-white",
  },
  { label: "Partial Checked", value: 1, colors: "bg-yellow-600 text-white" },
  {
    label: "Checked",
    value: 2,
    selectable: true,
    colors: "bg-green-600 text-white",
  },
  { label: "No Show", value: 3, colors: "bg-purple-600 text-white" },
];

export const bikeSoftStatuses = [
  {
    label: "Not Checked",
    value: 0,
    selectable: true,
    colors: "bg-slate-600 text-white",
  },
  {
    label: "Checked",
    value: 1,
    selectable: true,
    colors: "bg-green-600 text-white",
  },
  { label: "No Show", value: 2, colors: "bg-purple-600 text-white" },
];

export const reservationPaidStatuses = [
  { label: "Unpaid", value: "0", colors: "bg-red-600 text-white" },
  { label: "Paid", value: "10", colors: "bg-green-800 text-white" },
  { label: "Partially Paid", value: "20", colors: "bg-yellow-600 text-white" },
  {
    label: "Partially Refund",
    value: "30",
    colors: "bg-yellow-600 text-white",
  },
  { label: "Fully Refund", value: "40", colors: "bg-yellow-800 text-white" },
];

export const reservationPaymentTypes = [
  {
    label: "Credit Card",
    value: 0,
    colors: "bg-green-600 text-white",
    textColor: "text-green-600",
  },
  {
    label: "Transfer",
    value: 10,
    colors: "bg-green-600 text-white",
    textColor: "text-green-600",
  },
  {
    label: "Cash",
    value: 20,
    colors: "bg-green-600 text-white",
    textColor: "text-green-600",
  },
  {
    label: "Refund",
    value: 30,
    colors: "bg-yellow-600 text-white",
    textColor: "text-yellow-600",
  },
];

export const reservationProductTypes = [
  { label: "Rental", value: 10 },
  { label: "Tour", value: 20 },
  { label: "Event Rental", value: 30 },
];

export const reservationPartnerTypes = [
  { label: "Default", value: 0 },
  { label: "Invoice", value: 10 },
  { label: "Prepaid", value: 20 },
  { label: "Discount", value: 30 },
  { label: "Commission", value: 40 },
  { label: "Pass", value: 50 },
  { label: "Direct Bill", value: 60 },
];

export const reservationPartnerDeliveryFeature = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];

export const reservationPartnerEmailTypes = [
  { label: "Automated ", value: 0 },
  { label: "Manual", value: 10 },
  { label: "Prepaid Vouchers", value: 20 },
  { label: "Portal", value: 30 },
  { label: "Fareharbor", value: 40 },
];

export const reservationPartnerRateSignTypes = [
  { label: "Sent ", value: 0 },
  { label: "Confirmed", value: 10 },
  { label: "Signed", value: 20 },
  { label: "TBD", value: 30 },
  { label: "Need To Come Back To", value: 40 },
];

export const reservationPartnerPaymentMethodTypes = [
  { label: "Wire ", value: 0 },
  { label: "Check", value: 10 },
  { label: "ACH", value: 20 },
  { label: "Credit Card", value: 30 },
];

export const reservationPartneCommissionTypes = [
  { label: "0 ", value: 0 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20 ", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "35 ", value: 35 },
  { label: "40", value: 40 },
  { label: "45", value: 45 },
  { label: "50", value: 50 },
];

export const reservationPartneFitRateTypes = [
  { label: "Custom", value: null },
  { label: "0 ", value: 0 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20 ", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "35 ", value: 35 },
];
export const partnerContactTypes = [
  { label: "Invoicing", value: 0 },
  { label: "Product", value: 10 },
  { label: "Sales", value: 20 },
];

export const initialBasket = {
  id: null,
  entityid: null,
  addbookingfee: false,
  reservationproductid: "",
  affilatecompanyid: "",
  vouchercode: "",
  customerid: null,
  note: "",
  // Information Ends
  availability: null,
  durationid: "",
  discountCode: "",
  discount: 0,
  startdate: dayjs().hour(9).minute(0).second(0).format(dateFormat),
  starthour: HOURS[0],
  fullname: "",
  checkinstoreid: "",
  dropoffstoreid: "",
  email: "",
  phone: "",
  itemgroups: [],
  itemgroupPool: [],
  addons: [],
  addonPool: [],
  insurancePool: [],
  wantInsurance: true,
  total: {
    pickuptotal: 0,
    dropofftotal: 0,
    receiptsubtotal: 0,
    receipttax: 0,
    receipttotal: 0,
    items: [],
  },
  haveAvailability: null,
};

export const insuranceAddonCode = "200";
export const eventAddonCode = "300";

export const reservationEmailTemplates = [
  { label: "Confirmation Mail", value: 0 },
  { label: "Payment Mail", value: 10 },
];
