import React from "react";
import PropTypes from "prop-types";
import useAuthority from "utils/hooks/useAuthority";
import { useSelector } from "react-redux";

const AuthorityCheck = (props) => {
  const { authority = [], children } = props;
  const userRoles = useSelector((state) => state?.auth?.user?.authority);
  const userPermissions = useSelector(
    (state) => state?.auth?.user?.permissions
  );
  const userAuthority = [
    ...(userRoles ? userRoles : []),
    ...(userPermissions ? userPermissions : []),
  ];

  const adminByPass =
    useAuthority(userAuthority, ["Admin"]) &&
    !authority.find((a) => a === "HideAdmin");
  const roleMatched = useAuthority(userAuthority, authority) || adminByPass;

  return roleMatched ? children : <></>;
};

AuthorityCheck.propTypes = {
  userAuthority: PropTypes.array,
  authority: PropTypes.array,
};

export default AuthorityCheck;
