const appConfig = {
  apiPrefix: process.env.REACT_APP_API_KEY,
  gtmCode: process.env.REACT_APP_GA_KEY,
  eventsUrl: process.env.REACT_APP_EVENTS_URL,
  accountUrl: process.env.REACT_APP_ACCOUNT_URL,
  stripeUrl: process.env.REACT_APP_STRIPE_URL,
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
};

export default appConfig;
