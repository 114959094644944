import { createSlice, current } from "@reduxjs/toolkit";
import { HOURS } from "components/ui/utils/constant";
import { initialBasket } from "constants/moduleConstants/reservation.constant";
import dayjs from "dayjs";

export const basketSlice = createSlice({
  name: "reservation/basket",
  initialState: {
    ...initialBasket,
  },
  reducers: {
    startBasket: (_, action) => {
      return initialBasket;
    },
    rebookBasket: (_, action) => {
      return action.payload;
    },
    onInformationFormChange: (state, action) => {
      let mainFields = ["reservationproductid", "affilatecompanyid"];
      if (mainFields.includes(action.payload.field)) {
        let newState = {
          ...initialBasket,
          id: state.id || null,
          availability: state.availability || null,
          startdate: state.startdate || dayjs().format(),
          starthour: state.starthour || HOURS[0],
          note: state.note,
          vouchercode: state.vouchercode,
          customerid: state.customerid,
          affilatecompanyid: state.affilatecompanyid,
          reservationproductid: state.reservationproductid,
          checkinstoreid:
            action.payload.field === mainFields[0]
              ? action.payload.value.store
              : state.checkinstoreid,
          dropoffstoreid:
            action.payload.field === mainFields[0]
              ? action.payload.value.store
              : state.dropoffstoreid,
          addbookingfee:
            action.payload.field === mainFields[0]
              ? state.addbookingfee
              : action.payload.field === mainFields[1] &&
                action.payload.value.code === "unlimitedbiking1"
              ? true
              : false,
        };
        newState[action.payload.field] = action.payload.value;
        return newState;
      } else {
        state[action.payload.field] = action.payload.value;
      }
    },
    onAvailabiltySelect: (state, action) => {
      state.availability = action.payload;
      state.startdate = action.payload.date;
      state.checkinstoreid = {
        label: action.payload.items[0].reservationproduct?.productStore?.name,
        value: action.payload.items[0].reservationproduct?.productStore?.id,
      };
      state.dropoffstoreid = {
        label: action.payload.items[0].reservationproduct?.productStore?.name,
        value: action.payload.items[0].reservationproduct?.productStore?.id,
      };
    },
    onRentalAvailabilitySelect: (state, action) => {
      state.availability = action.payload;
    },
    onAvailabilityDurationSelect: (state, action) => {
      state.durationid = action.payload.duration;
    },
    // Cart Managemnet
    addToCart: (state, action) => {
      const itemInCart = state.itemgroups.find(
        (item) => item.id === action.payload.id
      );
      if (itemInCart) {
        itemInCart.quantity++;
      } else {
        state.itemgroups.push({ ...action.payload, quantity: 1 });
      }
    },
    incrementQuantity: (state, action) => {
      const item = state.itemgroups.find((item) => item.id === action.payload);
      item.quantity++;
      if (state.wantInsurance) {
        const insuranceAddon = item.addons.find(
          (addon) => addon.code === "200"
        );
        if (insuranceAddon) {
          insuranceAddon.quantity++;
        }
      }
    },
    decrementQuantity: (state, action) => {
      const item = state.itemgroups.find((item) => item.id === action.payload);
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
        if (state.wantInsurance) {
          const insuranceAddon = item.addons.find(
            (addon) => addon.code === "200"
          );
          if (insuranceAddon) {
            insuranceAddon.quantity--;
          }
        }
      }
    },
    removeItem: (state, action) => {
      const item = state.itemgroups.find((item) => item.id === action.payload);
      const removeItem = state.itemgroups.filter(
        (item) => item.id !== action.payload
      );
      state.itemgroups = removeItem;
      if (removeItem.length > 0) {
        const remainingAddons = state.addons.filter((addon) =>
          addon.itemGroupTypes.find((e) => e.id !== item.id)
        );
        state.addons = remainingAddons;
      } else {
        state.addons = [];
      }
    },
    // Addon Management
    setAddonPool: (state, action) => {
      state.addonPool = [...action.payload];
    },
    addToCartAddon: (state, action) => {
      const addonInCart = state.addons.find(
        (addon) => addon.id === action.payload.id
      );
      if (addonInCart) {
        addonInCart.quantity++;
      } else {
        state.addons.push({ ...action.payload, quantity: 1 });
      }
    },
    incrementQuantityAddon: (state, action) => {
      const addon = state.addons.find((addon) => addon.id === action.payload);
      addon.quantity++;
    },
    decrementQuantityAddon: (state, action) => {
      const addon = state.addons.find((addon) => addon.id === action.payload);
      if (addon.quantity === 1) {
        addon.quantity = 1;
      } else {
        addon.quantity--;
      }
    },
    selectQuantityAddon: (state, action) => {
      const addonInCart = state.addons.find(
        (addon) => addon.id === action.payload.addon.id
      );
      if (addonInCart) {
        addonInCart.quantity = action.payload.quantity;
      } else {
        state.addons.push({
          ...action.payload.addon,
          quantity: action.payload.quantity,
        });
      }
    },
    removeAddon: (state, action) => {
      const removeAddon = state.addons.filter(
        (addon) => addon.id !== action.payload
      );
      const belongItemGroup = state.itemgroups.find((item) =>
        item.addons.find((a) => a.id === action.payload)
      );
      let tempObject = { ...belongItemGroup };
      if (belongItemGroup) {
        let tempArray = tempObject.addons.filter(
          (addon) => addon.id !== action.payload
        );
        belongItemGroup.addons = tempArray;
      }
      state.addons = removeAddon;
    },
    setAddonSelect: (state, action) => {
      let selectedAddons = current(state.addons);
      selectedAddons.forEach((addon) => {
        const itemList = addon.itemGroupTypes;
        let addonQuantity = addon.quantity;
        itemList.map((item) => {
          const basketItem = state.itemgroups.find((a) => a.id === item.id);
          const basketItemTypeQuantity = state.itemgroups
            .filter((basketGroup) => basketGroup.id === item.id)
            ?.reduce((partialSum, a) => partialSum + a.quantity, 0);
          if (basketItem) {
            let tempArray = basketItem.addons.filter((a) => a.id !== addon.id);
            if (addonQuantity === 0) {
              basketItem.addons = [...tempArray];
            } else if (basketItemTypeQuantity < addonQuantity) {
              basketItem.addons = [
                ...tempArray,
                {
                  ...addon,
                  quantity: basketItemTypeQuantity,
                },
              ];
              addonQuantity -= basketItemTypeQuantity;
            } else if (basketItemTypeQuantity >= addonQuantity) {
              basketItem.addons = [
                ...tempArray,
                {
                  ...addon,
                  quantity: addonQuantity,
                },
              ];
              addonQuantity = 0;
            }
          }
        });
      });
    },
    // Insurance Management
    setInsurancePool: (state, action) => {
      state.insurancePool = [...action.payload];
    },
    setInsuranceWant: (state, action) => {
      state.wantInsurance = action.payload;
    },
    setInsuranceSelect: (state, action) => {
      const belongItemGroup = state.itemgroups.find(
        (item) => item.id === action.payload.itemGroupType.id
      );
      const qty = state.itemgroups
        .filter((item) => item.id === action.payload.itemGroupType.id)
        .reduce((partialSum, a) => partialSum + a?.quantity, 0);
      if (belongItemGroup) {
        let tempArray = belongItemGroup.addons.filter(
          (addon) => addon.code !== "200"
        );
        belongItemGroup.addons = [
          ...tempArray,
          {
            ...action.payload.addon,
            quantity: qty,
          },
        ];
      }
      state.wantInsurance = true;
    },
    clearInsuranceSelect: (state, action) => {
      const belongItemGroup = state.itemgroups.find(
        (item) => item.id === action.payload.itemGroupType.id
      );
      if (belongItemGroup) {
        const removeInsurance = belongItemGroup.addons.filter(
          (addon) => addon.id !== action.payload.addon.id
        );
        belongItemGroup.addons = removeInsurance;
      }
      state.wantInsurance = false;
    },
    setItemgroupPool: (state, action) => {
      state.itemgroupPool = action.payload;
    },
    // Event Management
    addToCartEvent: (state, action) => {
      const itemInCart = state.itemgroups.find(
        (item) =>
          item.id === action.payload.id &&
          item.availabilityid === action.payload.availabilityid
      );
      if (itemInCart) {
        itemInCart.quantity++;
      } else {
        state.itemgroups.push({ ...action.payload, quantity: 1 });
      }
    },
    incrementQuantityEvent: (state, action) => {
      const item = state.itemgroups.find(
        (item) =>
          item.id === action.payload.id &&
          item.availabilityid === action.payload.availabilityid
      );
      item.quantity++;
      item.bikes.push(action.payload.newBike);
      if (state.wantInsurance) {
        const insuranceAddon = item.addons.find(
          (addon) => addon.code === "200"
        );
        if (insuranceAddon) {
          insuranceAddon.quantity++;
        }
      }
    },
    decrementQuantityEvent: (state, action) => {
      const item = state.itemgroups.find(
        (item) =>
          item.id === action.payload.id &&
          item.availabilityid === action.payload.availabilityid
      );
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
        item.bikes.pop();
        if (state.wantInsurance) {
          const insuranceAddon = item.addons.find(
            (addon) => addon.code === "200"
          );
          if (insuranceAddon) {
            insuranceAddon.quantity--;
          }
        }
      }
    },
    removeItemEvent: (state, action) => {
      const item = state.itemgroups.find(
        (item) =>
          item.id === action.payload.id &&
          item.availabilityid === action.payload.availabilityid
      );
      const removeItem = state.itemgroups.filter(
        (item) => item.availabilityid !== action.payload.availabilityid
      );
      state.itemgroups = removeItem;
      if (removeItem.length > 0) {
        const remainingAddons = state.addons.filter((addon) =>
          addon.itemGroupTypes.find((e) => e.id !== item.id)
        );
        state.addons = remainingAddons;
      } else {
        state.addons = [];
      }
    },
    setBikeLocation: (state, action) => {
      const item = state.itemgroups.find(
        (item) =>
          item.id === action.payload.id &&
          item.availabilityid === action.payload.availabilityid
      );
      item.bikes[action.payload.bikeIndex][action.payload.type] =
        action.payload.location;
    },
    addBikeAddon: (state, action) => {
      const item = state.itemgroups.find(
        (item) =>
          item.id === action.payload.id &&
          item.availabilityid === action.payload.availabilityid
      );
      item.bikes[action.payload.bikeIndex].addons.push({
        ...action.payload.addon,
        quantity: 1,
      });
    },
    removeBikeAddon: (state, action) => {
      const item = state.itemgroups.find(
        (item) =>
          item.id === action.payload.id &&
          item.availabilityid === action.payload.availabilityid
      );
      let removedArray = item.bikes[action.payload.bikeIndex].addons.filter(
        (a) => a.id !== action.payload.addonid
      );
      item.bikes[action.payload.bikeIndex].addons = removedArray;
    },
    // Date and Duration Management (Rental)
    onDateSelect: (state, action) => {
      let newState = {
        ...initialBasket,
        id: state.id || null,
        note: state.note,
        vouchercode: state.vouchercode,
        customerid: state.customerid,
        affilatecompanyid: state.affilatecompanyid,
        reservationproductid: state.reservationproductid,
        checkinstoreid: state.checkinstoreid,
        dropoffstoreid: state.dropoffstoreid,
        starthour: state.starthour,
        addbookingfee: state.addbookingfee,
      };
      newState.startdate = action.payload;
      return newState;
    },
    onHourSelect: (state, action) => {
      state.starthour = action.payload;
    },
    onDurationSelect: (state, action) => {
      let newState = {
        ...initialBasket,
        id: state.id || null,
        note: state.note,
        vouchercode: state.vouchercode,
        customerid: state.customerid,
        affilatecompanyid: state.affilatecompanyid,
        reservationproductid: state.reservationproductid,
        checkinstoreid: state.checkinstoreid,
        dropoffstoreid: state.dropoffstoreid,
        startdate: state.startdate,
        starthour: state.starthour,
        addbookingfee: state.addbookingfee,
      };
      newState.durationid = action.payload;
      return newState;
    },
    onCheckinStoreSelect: (state, action) => {
      state.checkinstoreid = action.payload;
    },
    onDropoffStoreSelect: (state, action) => {
      state.dropoffstoreid = action.payload;
    },
    // Total
    setBasketTotal: (state, action) => {
      state.total = action.payload;
    },
    setBasketDiscount: (state, action) => {
      state.discount = action.payload.discount;
      state.discountCode = action.payload.discountCode;
    },
    updateDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setBasketId: (state, action) => {
      state.id = action.payload;
    },
    setBasketEntityId: (state, action) => {
      state.entityid = action.payload;
    },
    setAddBookingFee: (state, action) => {
      state.addbookingfee = action.payload;
    },
    setAvailabilityExist: (state, action) => {
      state.haveAvailability = action.payload;
    },
    // Delivery Featured Actions
    setEndDate: (state, action) => {
      state.enddate = action.payload;
    },
    setEndDateManuel: (state, action) => {
      state.enddatemanuel = true;
    },
    setPartnerPickupLocation: (state, action) => {
      state.affiliatecompanylocationpickupid = action.payload;
    },
    setPartnerDropoffLocation: (state, action) => {
      state.affiliatecompanylocationdropoffid = action.payload;
    },
    setDeliveryPickupLocation: (state, action) => {
      state.reservationproductrvlocationpickupid = action.payload;
    },
    setDeliveryDropoffLocation: (state, action) => {
      state.reservationproductrvlocationdropoffid = action.payload;
    },
    setDeliveryPickupAddress: (state, action) => {
      state.reservationproductrvlocationpickupaddress = action.payload;
    },
    setDeliveryDropoffAddress: (state, action) => {
      state.reservationproductrvlocationdropoffaddress = action.payload;
    },
  },
});
export const {
  startBasket,
  rebookBasket,
  setItemgroupPool,
  onInformationFormChange,
  onInformationFormComplete,
  onRentalAvailabilitySelect,
  onAvailabiltySelect,
  onAvailabilityDurationSelect,
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeItem,
  addToCartAddon,
  selectQuantityAddon,
  decrementQuantityAddon,
  incrementQuantityAddon,
  removeAddon,
  addToCartEvent,
  decrementQuantityEvent,
  incrementQuantityEvent,
  removeItemEvent,
  setBikeLocation,
  addBikeAddon,
  removeBikeAddon,
  setInsurancePool,
  setAddonPool,
  setAddonSelect,
  setInsuranceSelect,
  clearInsuranceSelect,
  setInsuranceWant,
  onDateSelect,
  onHourSelect,
  onDurationSelect,
  onCheckinStoreSelect,
  onDropoffStoreSelect,
  setBasketTotal,
  setBasketDiscount,
  setBasketId,
  setBasketEntityId,
  updateDiscount,
  setAddBookingFee,
  setAvailabilityExist,
  setPartnerPickupLocation,
  setPartnerDropoffLocation,
  setDeliveryPickupLocation,
  setDeliveryDropoffLocation,
  setDeliveryPickupAddress,
  setDeliveryDropoffAddress,
  setEndDate,
  setEndDateManuel,
} = basketSlice.actions;

export default basketSlice.reducer;
