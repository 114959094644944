import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  apiGetUserPermission,
  apiSignIn,
  apiSignInWithUser,
  apiSignUp,
} from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const { token } = resp.data.data;
        dispatch(onSignInSuccess(token));
        if (resp.data) {
          const permission = await apiGetUserPermission(resp.data.data.token);
          if (permission) {
            dispatch(
              setUser(
                {
                  avatar: "",
                  userName: resp.data.data.fullname,
                  phone: resp.data.data.phone,
                  authority: resp.data.data.roles,
                  permissions: permission.data.data.map((a) => a.code),
                  email: resp.data.data.email,
                  id: resp.data.data.id,
                } || {
                  avatar: "",
                  userName: "Anonymous",
                  phone: "",
                  authority: ["USER"],
                  email: "",
                  id: "",
                }
              )
            );
            const redirectUrl = query.get(REDIRECT_URL_KEY);
            navigate(
              redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            );
            return {
              status: "success",
              message: "",
            };
          }
        }
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  };

  const signInWithUser = async (id) => {
    try {
      const resp = await apiSignInWithUser(id);
      if (resp.data) {
        const { token } = resp.data.data;
        dispatch(onSignInSuccess(token));
        if (resp.data) {
          setTimeout(async () => {
            const permission = await apiGetUserPermission(resp.data.data.token);
            if (permission) {
              dispatch(
                setUser(
                  {
                    avatar: "",
                    userName: resp.data.data.fullname,
                    phone: resp.data.data.phone,
                    authority: resp.data.data.roles,
                    permissions: permission.data.data.map((a) => a.code),
                    email: resp.data.data.email,
                    id: resp.data.data.id,
                  } || {
                    avatar: "",
                    userName: "Anonymous",
                    phone: "",
                    authority: ["USER"],
                    email: "",
                    id: "",
                  }
                )
              );
              const redirectUrl = query.get(REDIRECT_URL_KEY);
              navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
              );
              return {
                status: "success",
                message: "",
              };
            }
          }, 1000);
        }
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    // There is no signout api right now.
    // await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signInWithUser,
    signUp,
    signOut,
  };
}

export default useAuth;
